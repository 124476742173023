<template>
   <section class="cont">
      <!-- 面包屑  /douyin-platform -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>营销云</el-breadcrumb-item>
            <el-breadcrumb-item>配置</el-breadcrumb-item>
            <el-breadcrumb-item>抖音开发平台</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-col class="main">
         <el-row class="allocation">
            <!-- 抖音 -->
            <el-row class="deploy" style="margin-top: 3rem">
               <el-row class="deploy-box">
                  <el-row class="title">
                     <el-row class="title-text">抖音开放平台</el-row>
                     <el-row style="float: right" v-show="tab == '1'">
                        <el-button v-show="thrill == false"  @click="thrill = true">返回</el-button>
                        <el-button type="primary" v-show="thrill == false" @click="save('dy')">保存</el-button>
                        <el-button v-show="thrill == true" icon="el-icon-edit" circle @click="modification('trill')"></el-button>
                     </el-row>
                  </el-row>
                  <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                     <el-menu-item index="1">基本信息</el-menu-item>
                     <el-menu-item index="2">权限配置</el-menu-item>
                  </el-menu>
                  <el-row v-show="tab === '1'">
                     <el-col class="message" v-show="thrill == false">
                        <el-col style="display: flex">
                           <el-row class="content">抖音应用openId</el-row>
                           <el-row><el-input placeholder="抖音应用openId" v-model="openId" type="text"></el-input></el-row>
                        </el-col>
                        <el-col style="display: flex">
                           <el-row class="content">应用唯一标识Client Key</el-row>
                           <el-row><el-input v-model="clientKey" placeholder="应用唯一标识Client Key" type="text"></el-input></el-row>
                        </el-col>
                        <el-col style="display: flex">
                           <el-row class="content">应用唯一标识对应的密钥Client Secret</el-row>
                           <el-row><el-input v-model="clientSecret" placeholder="应用唯一标识对应的密钥Client Secret" type="text"></el-input></el-row>
                        </el-col>
                        <el-col style="display: flex">
                           <el-row class="content">授权回调地址</el-row>
                           <el-row><el-input v-model="address" placeholder="授权回调地址" type="text"></el-input></el-row>
                        </el-col>
                        <el-col style="display: flex">
                           <el-row class="content">授权状态标识</el-row>
                           <el-row><el-input placeholder="授权状态标识" v-model="identification" type="text"></el-input></el-row>
                        </el-col>
                     </el-col>
                     <el-col class="message" v-show="thrill == true">
                        <el-col style="display: flex">
                        <el-row class="content">抖音应用openId</el-row>
                        <el-row class="viewupdate">{{ openId }}</el-row></el-col>
                        <el-col style="display: flex">
                        <el-row class="content">应用唯一标识Client Key</el-row>
                        <el-row class="viewupdate">{{ clientKey }}</el-row></el-col>
                        <el-col style="display: flex">
                        <el-row class="content">应用唯一标识对应的密钥Client Secret</el-row>
                        <el-row class="viewupdate">{{ clientSecret }}</el-row></el-col>
                        <el-col style="display: flex">
                        <el-row class="content">授权回调地址</el-row>
                        <el-row class="viewupdate">{{ address }}</el-row></el-col>
                        <el-col style="display: flex">
                        <el-row class="content">授权状态标识</el-row>
                        <el-row class="viewupdate">{{identification}}</el-row></el-col>
                     </el-col>
                  </el-row>
                  <el-row v-show="tab === '2'">
                     <el-menu
                           :default-active="activeIndex2"
                           class="el-menu-demo"
                           mode="horizontal"
                           @select="handleSelects">
                        <el-menu-item v-for="item in tableData" :key="item.id" :index="item.id"  >{{item.value}}</el-menu-item>
                     </el-menu>
                     <el-table :data="configuration" style="width: 100%">
                        <el-table-column prop="authName" label="权限名称"></el-table-column>
                        <el-table-column prop="scopes" label="Scope">
                           <template slot-scope="scope"><p v-for="(item,index) in scope.row.content" :key="index">{{item}}</p></template>
                        </el-table-column>
                        <el-table-column label="状态">
                           <template slot-scope="scope">
                              <el-switch @change="states(scope.row,scope.$index)" v-if="switchshow"  :active-value="!scope.row.switch"></el-switch>
                           </template>
                        </el-table-column>
                     </el-table>
                  </el-row>
               </el-row>
            </el-row>
         </el-row>
      </el-col>
   </section>
</template>

<script>
import { urlObj } from "@/api/interface";
import { mapState } from 'vuex'
export default {
   name: "douyin-platform",
   watch: {
      hotelInfo: {
         handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id  && oldVal) {
               this.hotelId = this.hotelInfo.id
               this.seeabout()
            }
         },
         deep: true
      }
   },
   computed: {
      ...mapState(['hotelInfo'])
   },
   data() {
      return {
         drawer:false,
         activeIndex2:'VIDEO',
         tableData: [],
         configuration:[],
         activeIndex: "1",
         tab: "1",
         thrill:true,
         openId:'',              //抖音应用openId
         clientKey:'',           //应用唯一标识Client Key
         clientSecret:'',        //应用唯一标识对应的密钥clientSecret
         address:'',             //授权回调地址
         identification:'',      //授权状态标识

         tabs:'VIDEO',
         switchshow:true,
         douyincontent:'',
         hotelId:'',
      }
   },
   mounted() {
      this.hotelId =    this.hotelInfo.id
      this.seeabout()
      // this.getAppletPages()
   },
   methods:{
      //查询配置公众号
      seeabout() {
         const url = urlObj.EnquiryPublicAccount;
         var ads = ["wechat-official","douyin-platform","douyin-scope","wechat-applet"]
         const param = {
            hotelId: this.hotelId,
            limit: this.limit,
            page: this.page,
            cfgCodes: ads,
         };
         this.$axios.post(url, param, 'json').then((res) => {
            this.tableData = res.records;
            this.tableData.forEach((item) => {
               item.content = JSON.parse(item.content);
               if (item.cfgCode === "douyin-platform") {
                  let content = item.content;
                  this.openId = content.id;
                  this.clientKey = content.clientKey;
                  this.clientSecret = content.clientSecret;
                  this.address = content.redirectUri;
                  this.identification = content.state;
               }
               if (item.cfgCode === "douyin-scope") {
                  this.douyincontent = item.content;
               }
            });
            this.drawer = false
         });
      },

      //权限配置
      states(val,i){
         let contentscope = this.douyincontent.scope.split(",")
         let scope = val.scopes.split(",")
         this.switchshow = false
         if (val.switch == true){

            contentscope.map((item,index)=>{
               scope.map((items,i)=>{
                  if (contentscope[index] == items){
                     contentscope.splice(index,1)
                  }
               })
            })
            this.configuration[i].switch = false
            this.switchshow = true
         }else{
            contentscope.push(val.scopes)
            this.configuration[i].switch = true
            this.switchshow = true
         }
         var reg = new RegExp('"',"g");
         this.douyincontent.scope = contentscope.toString().replace(reg, "")
         let contentdata = {
            scope:contentscope.join(","),
            id: this.openId,
         }
         const url = urlObj.saveWechatHotelCfg;
         const param = {
            cfgCode: "douyin-scope",
            hotelId: this.hotelId,
            cfgName: '抖音权限配置',
            content:JSON.stringify(contentdata)
         }

         this.$axios.post(url, param).then((res) => {
            if (res.status == 200) {
               this.$message({
                  message: "修改成功!",
                  type: "success",
               });
               this.trillQuerys()
            }
         })
      },
      //查询抖音权限配置
      trillQuerys(){
         // let scope = ['toutiao.video.create', 'toutiao.video.data', 'xigua.video.data', 'xigua.video.create']
         const url = urlObj.trillQuery;
         const param = {
            type:this.tabs
         };
         this.$axios.post(url, param).then((res) => {
            this.configuration = res.records
            this.configuration.forEach((item,i)=>{
               item.switch = false
               this.switchshow = false
               item.content = item.scopes.split(",")
               if (this.douyincontent.scope.search(item.scopes) != -1){
                  item.switch = true
               }else{
                  item.switch = false
               }
            })
            this.switchshow = true
         })
      },

      //抖音开发平台 tab
      handleSelect(key, keyPath) {
         if(key == 2){
            this.trillQuerys()
         }
         this.tab = key;
      },
      handleSelects(key, keyPath){
         this.tabs = key;
         this.trillQuerys()
      },
      modification(data) {
         if (data === 'OfficialAccounts') {
            this.savechanges = false;
         }
         if (data === 'trill') {
            this.thrill = false
         }
      },
      save(identification) {
         if(identification == 'gzh'){
            if (this.cfgName == "") {
               this.$message.error("微信公众号ID不能为空!");
               return;
            }
            if (this.AppId == "") {
               this.$message.error("APPID不能为空!");
               return;
            }
            if (this.AppSecret == "") {
               this.$message.error("开发者密码不能为空!");
               return;
            }
            if (this.Token == "") {
               this.$message.error("token命令不能为空!");
               return;
            }
            if (this.encodingAESKey == "") {
               this.$message.error("消息密钥不能为空!");
               return;
            }
            const url = urlObj.saveWechatHotelCfg;
            const contents = {
               id: this.id,
               appId: this.AppId,
               appSecret: this.AppSecret,
               token: this.Token,
               encodingAesKey: this.encodingAESKey,
            };
            const param = {
               cfgCode: "wechat-official",
               hotelId: this.hotelId,
               cfgName: '微信公众号',
               content: JSON.stringify(contents),
            };
            this.$axios.post(url, param).then((res) => {
               if (res.status === 200) {
                  this.$message({
                     message: "修改成功!",
                     type: "success",
                  });
                  this.savechanges = true;
                  this.seeabout();
               }
            });
         }

         if(identification == 'dy'){
            if (this.openId == "") {
               this.$message.error("抖音应用openId不能为空!");
               return;
            }
            if (this.clientKey == "") {
               this.$message.error("应用唯一标识Client Key不能为空!");
               return;
            }
            if (this.clientSecret == "") {
               this.$message.error("应用唯一标识对应的密钥Client Secret不能为空!");
               return;
            }
            if (this.address == "") {
               this.$message.error("授权回调地址不能为空!");
               return;
            }
            if (this.identification == "") {
               this.$message.error("授权状态标识不能为空!");
               return;
            }
            const url = urlObj.saveWechatHotelCfg;
            const contents = {
               id: this.openId,
               clientKey: this.clientKey,
               clientSecret: this.clientSecret,
               redirectUri: this.address,
               state: this.identification
            };
            const param = {
               cfgCode: "douyin-platform",
               hotelId: this.hotelId,
               cfgName: '抖音开放平台',
               content: JSON.stringify(contents),
            };
            this.$axios.post(url, param).then((res) => {
               if (res.status == 200){
                  this.$message({
                     message: "修改成功!",
                     type: "success",
                  });
                  this.seeabout();
                  this.thrill = true
               }
            });
         }
      },
   }

}
</script>

<style scoped>
.tips{
   background: rgb(92 213 81);
   color: #fff;
   font-size: 12px;
   margin-left: 15px;
   padding: 0 5px;
   border-radius: 2px;
}
.main {
   width: 100%;
   background: white;
}
.allocation {
   width: 100%;
}
.deploy {
   width: 97%;
   box-shadow: 0px 0px 4px #c2d7fb inset;
   margin: auto;
   margin-top: 2rem;
   margin-bottom: 2rem;
}
.deploy-box{
   width: 97%;
   margin: auto;
}
.describe{font-size: 12px; color: #bac2ce;margin-top: 1rem;}
.viewupdate{font-size: 15px;color: black;}
.title {
   width: 100%;
   height: 60px;
   line-height: 60px;
   border-bottom: 1px solid #dde0e7;
   font-size: 1.1rem;
   padding-left: 1rem;
}
.title-text{
   float: left;
   font-size: 18px;
   font-weight: 900;
}
.content-box{
   display: flex;
}
.basic {
   width: 100%;
   line-height: 60px;
   font-size: 1.1rem;
   padding-left: 1rem;
}
.logo{
   width: 50px;
   height: 50px;
   position: relative;
   top: 18px;
   display: initial;
}
.basic .el-row {
   font-size: 17px;
   width: 350px;
   border-bottom: 1px solid #dedede;
}
/deep/ .el-table__header{margin-top:1rem;}
.message {
   width: 100%;
   padding-bottom: 2rem;
   padding-left: 1rem;
}
.message .el-col {
   margin-top: 1.5rem;
}
.message .el-col .el-input {
   min-width: 300px;
}
/deep/ .has-gutter tr th{text-align: center; background: #f2f2f2; color: #667688;font-weight: bold;}
/deep/ tbody .el-table__row td{text-align: center;}
.content {
   width: 180px;
   text-align: left;
   font-size: 14px;
}
@media screen and (max-width: 1366px) {
   .main {
      width: 1200px;
   }
}
.content-text{
   display: flex;
   padding: 1.5rem 0;
}
.content-titel{
   text-indent: 1em;
}
.icon{
   padding: 1px;
   border: 1px solid #409eff;
   background: #409eff;
   margin-left: 1rem;
}
.form-text{
   margin:0 1rem 1rem;
}
.text-btn{
   margin: 0 10px;
}
.bottom-btn{
   border-top: 1px solid #dedede;
   margin-top: 1rem;
   width: 350px;
   text-indent: 0;
}
.bottom-btn button{
   padding: 20px 10px;
}
.qrcodeMap{
   margin: 30px 140px;
   width: 150px;
}
.uploadbtn{
   margin-left: 140px;
}
/deep/.el-form-item{
   margin-bottom: 0;
}
/deep/.el-checkbox{
   width: 33%;
   padding: 5px 0;
}
/deep/.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner{
   background-color: #409eff;
   border-color: #DCDFE6;
}
/deep/.el-checkbox__input.is-disabled + span.el-checkbox__label{
   color: #000;
   cursor: not-allowed;
}
.img-box{
   width: 220px;
   height: 250px;
   border: 1px solid #dedede;
   margin: 20px;
   padding: 15px;
   text-align: center;
   font-size: 12px;
}
.img-box p{
   opacity: 0.5;
}
/deep/.el-drawer__open .el-drawer.ttb{
   height: 150px;
   width: 350px;
   margin: 15% auto;
   text-align: center;
}
/deep/.el-drawer__header{
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   color: #2d2d2d;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   margin-bottom: 32px;
   border: none;
   padding: 20px;
   padding-bottom: 0;
   font-size: 18px;
}
/deep/ .el-drawer:focus {
   outline: none;
}
.el-drawer /deep/ :focus {
   outline: none;
}
/deep/.el-drawer__header > :first-child{
   outline: none;
}
</style>
